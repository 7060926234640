export const OperatorIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0451 5.98951H9.37993V5.34778H10.0451V5.98951ZM10.6399 4.77649H8.78516V6.55298H10.6399V4.77649Z" fill="currentColor" />
            <path d="M10.0451 8.75952H9.37993V8.11779H10.0451V8.75952ZM10.6399 7.55432H8.78516V9.33082H10.6399V7.55432Z" fill="currentColor" />
            <path d="M12.8703 5.98951H12.2051V5.34778H12.8703V5.98951ZM13.4651 4.77649H11.6104V6.55298H13.4651V4.77649Z" fill="currentColor" />
            <path d="M12.8703 8.75952H12.2051V8.11779H12.8703V8.75952ZM13.4651 7.55432H11.6104V9.33082H13.4651V7.55432Z" fill="currentColor" />
            <path d="M11.5784 13.4165H10.8428V14.8252H11.5784V13.4165Z" fill="currentColor" />
            <path
                d="M15.2964 12.3677L14.6234 12.1642C14.5842 12.1485 14.5608 12.1016 14.5686 12.0546L14.7095 11.3581C14.7251 11.2642 14.6234 11.2094 14.5686 11.2798L13.8095 12.3285C13.7703 12.3755 13.7938 12.4537 13.8486 12.4694L14.5216 12.6729C14.5608 12.6885 14.5842 12.7355 14.5764 12.7824C14.5451 12.9311 14.4903 13.2363 14.4355 13.4789C14.4199 13.5728 14.5216 13.6276 14.5764 13.5572L15.3355 12.5085C15.3747 12.4616 15.3512 12.3833 15.2964 12.3677Z"
                fill="currentColor"
            />
            <path
                d="M13.3001 14.2853L13.2767 14.1835C13.2141 13.9409 13.1201 13.6983 13.0184 13.4557V14.8018H9.1602V13.4479H13.0184C12.9401 13.2679 12.8462 13.0801 12.7445 12.9079C12.7445 12.9001 12.7445 12.8922 12.7288 12.8766H8.56543V14.9426H13.6054V14.8644H13.441C13.3471 14.8644 13.2688 14.7861 13.2688 14.6922C13.2688 14.5983 13.3471 14.52 13.441 14.52H13.6054V14.2774H13.3001V14.2853Z"
                fill="currentColor"
            />
            <path d="M13.1123 12.892C13.3314 13.2833 13.5036 13.6902 13.6053 14.0972L13.1123 12.892Z" fill="currentColor" />
            <path
                d="M16.7061 11.8121C16.7061 12.0156 16.6748 12.2191 16.6122 12.4147C17.7078 12.9547 18.373 13.6512 18.373 14.4104C18.373 15.8817 15.9391 17.1103 12.6757 17.4312C12.3314 17.4625 11.9792 17.486 11.627 17.5016L11.9401 16.5938L8.73926 18.4016L11.9401 20.2094L11.674 19.4346C12.0183 19.419 12.3549 19.4033 12.6835 19.372C16.847 18.9964 20.0008 17.2434 20.0008 15.1304C20.0008 13.7843 18.7174 12.5947 16.7061 11.8043V11.8121Z"
                fill="currentColor"
            />
            <path
                d="M2 15.1459C2 17.1885 4.95039 18.8945 8.92598 19.3484L7.2747 18.4171L9.0512 17.4154C5.92081 17.0554 3.61998 15.8502 3.61998 14.4259C3.61998 13.2911 5.07561 12.3051 7.23557 11.7651V11.2407C4.15214 11.9216 2 13.4163 2 15.1537L2 15.1459Z"
                fill="currentColor"
            />
            <path
                d="M13.3553 15.3807H8.56578V14.9503H7.82231V11.6165C7.61884 11.6556 7.42319 11.7025 7.22754 11.7495V15.5216H13.6057V15.4199H13.4414C13.4414 15.4199 13.3787 15.4042 13.3553 15.3807Z"
                fill="currentColor"
            />
            <path
                d="M8.56543 15.3805H13.3549C13.308 15.3492 13.2688 15.3022 13.2688 15.2474C13.2688 15.1535 13.3471 15.0753 13.441 15.0753H13.6054V14.9579H8.56543V15.3883V15.3805Z"
                fill="currentColor"
            />
            <path
                d="M7.82328 3.5713H14.2719V9.74598C14.3893 9.73033 14.5067 9.71468 14.6319 9.72251C14.7101 9.72251 14.7884 9.73816 14.8666 9.75381V3H7.23633V11.2251C7.43198 11.1781 7.62763 11.139 7.8311 11.1077V3.5713H7.82328Z"
                fill="currentColor"
            />
            <path
                d="M7.82231 11.1078C7.61884 11.1469 7.42319 11.186 7.22754 11.2252V11.7495C7.42319 11.7026 7.61884 11.6556 7.82231 11.6165V11.1078Z"
                fill="currentColor"
            />
            <path
                d="M10.0451 11.538H9.37993V11.3893C9.17645 11.4049 8.98081 11.4284 8.78516 11.4519V12.1015H10.6399V11.311C10.4364 11.311 10.2408 11.3189 10.0451 11.3345V11.5302V11.538Z"
                fill="currentColor"
            />
            <path
                d="M9.37993 10.8963H9.42689C9.82601 10.865 10.233 10.8415 10.6399 10.8337V10.325H8.78516V10.9589C8.98081 10.9354 9.17645 10.912 9.37993 10.8963Z"
                fill="currentColor"
            />
            <path
                d="M10.0528 10.8963V11.3424C10.2485 11.3345 10.4441 11.3267 10.6476 11.3189V10.8337C10.2328 10.8337 9.82587 10.865 9.43457 10.8963H10.0606H10.0528Z"
                fill="currentColor"
            />
            <path
                d="M9.37993 11.3893V10.8963C9.17645 10.912 8.98081 10.9354 8.78516 10.9589V11.4519C8.98081 11.4285 9.17645 11.405 9.37993 11.3893Z"
                fill="currentColor"
            />
            <path
                d="M11.6094 10.8415C11.9381 10.8494 12.2589 10.8728 12.572 10.8963H12.6424C12.7442 10.685 12.8694 10.4972 13.0337 10.325H11.6016V10.8415H11.6094Z"
                fill="currentColor"
            />
            <path
                d="M12.2051 11.3583C12.0095 11.3426 11.8138 11.3348 11.6104 11.327V12.1096H12.479C12.4634 12.0078 12.4399 11.9061 12.4399 11.8043C12.4399 11.7183 12.4556 11.6322 12.4634 11.5461H12.2051V11.3661V11.3583Z"
                fill="currentColor"
            />
            <path
                d="M12.2051 11.3579V10.8962H12.5729C12.2599 10.8727 11.939 10.8492 11.6104 10.8414V11.3266C11.8138 11.3266 12.0095 11.3422 12.2051 11.3579Z"
                fill="currentColor"
            />
            <path
                d="M15.7036 14.8721C15.7975 14.8721 15.8757 14.7939 15.8757 14.6999C15.8757 14.606 15.7975 14.5278 15.7036 14.5278H14.874V14.8721H15.7036Z"
                fill="currentColor"
            />
            <path d="M14.2785 14.5278H13.6133V14.8721H14.2785V14.5278Z" fill="currentColor" />
            <path
                d="M13.2695 14.6999C13.2695 14.7939 13.3478 14.8721 13.4417 14.8721H13.606V14.5278H13.4417C13.3478 14.5278 13.2695 14.606 13.2695 14.6999Z"
                fill="currentColor"
            />
            <path d="M14.8741 14.5278H14.2793V14.8721H14.8741V14.5278Z" fill="currentColor" />
            <path
                d="M15.7036 15.4198C15.7975 15.4198 15.8757 15.3415 15.8757 15.2476C15.8757 15.1537 15.7975 15.0754 15.7036 15.0754H14.874V15.4198H15.7036Z"
                fill="currentColor"
            />
            <path
                d="M13.6137 15.3807H13.3555C13.3555 15.3807 13.4103 15.4198 13.4416 15.4198H14.8659V15.0754H13.6059V15.3885L13.6137 15.3807Z"
                fill="currentColor"
            />
            <path
                d="M13.2695 15.2401C13.2695 15.3027 13.3087 15.3418 13.3556 15.3731H13.6139V15.0601H13.4495C13.3556 15.0601 13.2774 15.1383 13.2774 15.2322L13.2695 15.2401Z"
                fill="currentColor"
            />
            <path
                d="M15.8756 15.7876C15.8756 15.6937 15.7973 15.6154 15.7034 15.6154H13.4417C13.3478 15.6154 13.2695 15.6937 13.2695 15.7876C13.2695 15.8815 13.3478 15.9598 13.4417 15.9598H15.7034C15.7973 15.9598 15.8756 15.8815 15.8756 15.7876Z"
                fill="currentColor"
            />
            <path
                d="M14.577 16.6642C14.8509 16.6642 15.0779 16.4608 15.0779 16.2103H14.084C14.084 16.4608 14.3109 16.6642 14.5848 16.6642H14.577Z"
                fill="currentColor"
            />
            <path
                d="M13.4648 11.4915C13.504 11.4915 13.5431 11.5072 13.5822 11.515C13.637 11.3819 13.7231 11.2567 13.8327 11.1472C13.864 11.1159 13.8953 11.0924 13.9266 11.0689C13.7857 11.0454 13.6527 11.022 13.504 10.9985C13.4883 11.0141 13.4805 11.0298 13.4648 11.0454V11.4915Z"
                fill="currentColor"
            />
            <path
                d="M13.5049 10.9982C13.6458 11.0217 13.7866 11.0452 13.9275 11.0687C14.0292 10.9826 14.1466 10.9121 14.2797 10.8652V10.5052C14.0214 10.5756 13.7866 10.7087 13.591 10.8887C13.5597 10.92 13.5362 10.9591 13.5049 10.9904V10.9982Z"
                fill="currentColor"
            />
            <path
                d="M13.4648 11.9764H13.4883C13.4883 11.8121 13.5196 11.6556 13.5822 11.5147C13.5431 11.5147 13.504 11.499 13.4648 11.4912V11.9764Z"
                fill="currentColor"
            />
            <path
                d="M14.7489 10.7869V10.4426C14.5845 10.4426 14.428 10.4739 14.2793 10.513V10.873C14.428 10.8182 14.5845 10.7869 14.7489 10.7869Z"
                fill="currentColor"
            />
            <path
                d="M13.2384 11.4599C13.1837 11.6242 13.1445 11.7964 13.1445 11.9764H13.4654V11.4912C13.3871 11.4834 13.3167 11.4756 13.2384 11.4599Z"
                fill="currentColor"
            />
            <path
                d="M13.4662 11.0452C13.3645 11.1704 13.294 11.3112 13.2393 11.4599C13.3175 11.4678 13.3958 11.4756 13.4662 11.4912V11.0452Z"
                fill="currentColor"
            />
            <path
                d="M16.7131 11.7964C16.7131 10.7243 15.9305 9.8869 14.874 9.74603V10.0904C15.7036 10.2156 16.3062 10.8573 16.361 11.679C16.4783 11.7182 16.5957 11.7651 16.7131 11.8121V11.7964Z"
                fill="currentColor"
            />
            <path
                d="M16.1183 12.7276C15.8522 13.1893 15.6487 13.6667 15.5235 14.1441L15.4922 14.2615C15.4922 14.2615 15.4922 14.2772 15.4922 14.285H15.8444L15.86 14.2302C15.9774 13.7841 16.1652 13.338 16.4157 12.8998C16.5017 12.7433 16.5722 12.5789 16.6191 12.4068C16.5174 12.352 16.4235 12.305 16.3061 12.2581C16.267 12.4224 16.2044 12.5789 16.1183 12.7198V12.7276Z"
                fill="currentColor"
            />
            <path
                d="M12.4785 11.3819C12.5959 11.3898 12.7133 11.3976 12.8229 11.4132C12.8385 11.3584 12.8542 11.3037 12.8698 11.2567V10.9202C12.7916 10.9202 12.7211 10.9045 12.6429 10.9045C12.5724 11.0532 12.5098 11.2176 12.4785 11.3819Z"
                fill="currentColor"
            />
            <path
                d="M13.042 10.3252H13.4646V10.4269C13.6994 10.2469 13.9733 10.1295 14.2785 10.0904V9.74603C13.8089 9.80864 13.3785 10.0043 13.042 10.333V10.3252Z"
                fill="currentColor"
            />
            <path d="M13.6524 14.2852C13.6524 14.2852 13.6524 14.2696 13.6524 14.2617L13.6133 14.113V14.293H13.6602L13.6524 14.2852Z" fill="currentColor" />
            <path
                d="M12.729 12.8923H13.1124C13.0811 12.8454 13.0655 12.7906 13.0342 12.7436C12.9168 12.548 12.8542 12.3367 12.815 12.1097H12.4707C12.5098 12.3837 12.5881 12.6497 12.7211 12.8923H12.729Z"
                fill="currentColor"
            />
            <path
                d="M12.7451 12.9154C12.8547 13.0954 12.9408 13.2754 13.0191 13.4554C13.1286 13.698 13.2147 13.9406 13.2773 14.1833L13.3008 14.285H13.606V14.105C13.5043 13.6824 13.3243 13.2833 13.113 12.892H12.7295C12.7295 12.892 12.7295 12.9076 12.7451 12.9233V12.9154Z"
                fill="currentColor"
            />
            <path
                d="M12.4785 11.3813C12.4707 11.4283 12.4707 11.4831 12.4629 11.5379H12.8072C12.8072 11.4987 12.8151 11.4518 12.8307 11.4127C12.7133 11.397 12.5959 11.3892 12.4864 11.3813H12.4785Z"
                fill="currentColor"
            />
            <path d="M12.8705 10.9198V10.8963H12.6436C12.7218 10.8963 12.8001 10.9041 12.8705 10.912V10.9198Z" fill="currentColor" />
            <path
                d="M16.3614 11.6792C16.3614 11.6792 16.3692 11.7575 16.3692 11.7888C16.3692 11.9453 16.3458 12.1018 16.3066 12.2583C16.4162 12.3053 16.5101 12.3601 16.6197 12.407C16.6745 12.2114 16.7058 12.0079 16.7136 11.8044C16.5962 11.7575 16.4788 11.7183 16.3614 11.6714V11.6792Z"
                fill="currentColor"
            />
            <path
                d="M14.6305 9.72241C14.5053 9.72241 14.3879 9.73024 14.2705 9.74589V10.0902C14.3879 10.0746 14.4975 10.0668 14.6227 10.0668C14.7088 10.0668 14.787 10.0824 14.8731 10.0981V9.75372C14.7948 9.74589 14.7166 9.73024 14.6383 9.72241H14.6305Z"
                fill="currentColor"
            />
            <path
                d="M12.6436 10.8963H12.8705V10.9198C12.9175 10.9198 12.9644 10.9354 13.0114 10.9354C13.0896 10.8024 13.1679 10.685 13.2775 10.5754C13.3322 10.5207 13.4027 10.4737 13.4653 10.4267V10.325H13.0427C12.8783 10.4894 12.7453 10.685 12.6514 10.8963H12.6436Z"
                fill="currentColor"
            />
            <path
                d="M12.8004 11.5385H12.4561C12.4483 11.6245 12.4326 11.7106 12.4326 11.7967C12.4326 11.8984 12.4561 12.0002 12.4717 12.1019H12.8161C12.8004 12.0002 12.777 11.8984 12.777 11.7967C12.777 11.7106 12.7926 11.6245 12.8004 11.5385Z"
                fill="currentColor"
            />
            <path
                d="M13.011 10.9356C12.964 10.9356 12.9171 10.9199 12.8701 10.9199V11.2564C12.9092 11.1469 12.9562 11.0373 13.011 10.9434V10.9356Z"
                fill="currentColor"
            />
        </svg>
    )
}
