import { border } from './border'
import { boxShadow } from './boxShadow'

export type Variant = 'outlined' | 'shadowed'

export const variant: { [key in Variant]: { border: string; boxShadow: string } } = {
    outlined: {
        border: border.primary,
        boxShadow: 'none',
    },
    shadowed: {
        border: 'none',
        boxShadow: boxShadow.primary,
    },
}

export default variant
