export const UserIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4237_3509" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="5" y="2" width="13" height="18">
                <path d="M17.375 2H5V20H17.375V2Z" fill="white" />
            </mask>
            <g mask="url(#mask0_4237_3509)">
                <mask id="path-2-inside-1_4237_3509" fill="white">
                    <path d="M13.5369 11.7449C12.7982 12.1596 11.9653 12.3774 11.1182 12.3774C10.271 12.3774 9.43811 12.1596 8.69941 11.7449C6.68454 12.5549 4.79004 14.374 4.79004 16.732V19.5996H17.4463V16.732C17.4463 14.3695 15.5507 12.5549 13.5369 11.7494" />
                </mask>
                <path
                    d="M8.69941 11.7449L9.18894 10.8729L8.77106 10.6383L8.32642 10.817L8.69941 11.7449ZM4.79004 19.5996H3.79004V20.5996H4.79004V19.5996ZM17.4463 19.5996V20.5996H18.4463V19.5996H17.4463ZM13.0474 10.8729C12.4582 11.2037 11.7939 11.3774 11.1182 11.3774V13.3774C12.1368 13.3774 13.1382 13.1155 14.0264 12.6169L13.0474 10.8729ZM11.1182 11.3774C10.4425 11.3774 9.77813 11.2037 9.18894 10.8729L8.20989 12.6169C9.09809 13.1155 10.0996 13.3774 11.1182 13.3774V11.3774ZM8.32642 10.817C6.1131 11.7068 3.79004 13.8011 3.79004 16.732H5.79004C5.79004 14.9469 7.25598 13.4029 9.07241 12.6727L8.32642 10.817ZM3.79004 16.732V19.5996H5.79004V16.732H3.79004ZM4.79004 20.5996H17.4463V18.5996H4.79004V20.5996ZM18.4463 19.5996V16.732H16.4463V19.5996H18.4463ZM18.4463 16.732C18.4463 13.796 16.1213 11.7061 13.9083 10.8209L13.1655 12.6778C14.98 13.4036 16.4463 14.943 16.4463 16.732H18.4463Z"
                    fill="currentColor"
                    mask="url(#path-2-inside-1_4237_3509)"
                />
                <path
                    d="M15.0677 6.44938C15.0677 7.23049 14.8361 7.99406 14.4021 8.64353C13.9681 9.293 13.3513 9.7992 12.6297 10.0981C11.908 10.397 11.1139 10.4753 10.3478 10.3229C9.58173 10.1705 8.87802 9.79433 8.32569 9.242C7.77336 8.68967 7.39722 7.98596 7.24483 7.21986C7.09245 6.45376 7.17066 5.65967 7.46957 4.93801C7.76849 4.21636 8.27469 3.59955 8.92417 3.16559C9.57364 2.73163 10.3372 2.5 11.1183 2.5C12.1658 2.5 13.1703 2.91609 13.911 3.65675C14.6516 4.3974 15.0677 5.40194 15.0677 6.44938Z"
                    stroke="currentColor"
                />
            </g>
        </svg>
    )
}
