import { makeStyles } from '@material-ui/core'
import { sidebar } from 'themes'

export const useStyles = makeStyles((theme) => ({
    root: {
        width: sidebar.width,
        backgroundColor: theme.palette.green.dark,
        position: 'fixed',
        height: '100%',
        transition: 'width 0.3s cubic-bezier(0.4, 0.0, 0.2, 1)',
        overflowX: 'hidden',
        marginTop: 32,
    },
    collapsed: {
        width: sidebar.widthCollapsed,
    },
    listItem: {
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundRepeat: 'no-repeat',
        color: '#FFF',
        width: '100%',
        height: 22,
        borderBottom: `1px solid ${theme.palette.green.light}`,
        cursor: 'pointer',
        padding: '9px 8px',
        '& .MuiTypography-body1': {
            fontFamily: 'Hiragino Sans, sans-serif',
            fontSize: '15px',
            fontWeight: '400 !important',
            letterSpacing: 0,
            lineHeight: '40px',
        },
        '&.Mui-selected': {
            '& svg path': {
                color: theme.palette.green.main,
            },
            '& .MuiTypography-body1': {
                color: theme.palette.green.main,
            },
        },
        '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
            backgroundColor: theme.palette.background.default,
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: theme.palette.green.light,
        },
    },
    iconItem: {
        width: 22,
        height: 22,
        color: '#FFF',
    },
    listItemText: {
        whiteSpace: 'nowrap',
        flexShrink: 1,
        margin: '0 0 0 8px',
        overflow: 'hidden',
    },
    nonOpacity: {
        display: 'none',
    },
    expandedIcon: {
        height: 40,
        width: '100%',
        justifyContent: 'end',
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    collapsedIcon: {
        justifyContent: 'center',
    },
}))
