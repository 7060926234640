import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'

interface IProps {
    isCollapsed: boolean
    title?: string
    children: React.ReactElement
}

const MenuItem: React.FC<IProps> = (props) => {
    const tooltipClasses = useTooltipStyles()
    const { isCollapsed, title = '', children } = props

    return isCollapsed ? (
        <Tooltip classes={tooltipClasses} title={title} placement="right" arrow>
            {children}
        </Tooltip>
    ) : (
        children
    )
}

export default MenuItem

const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.green.dark,
        color: '#FFF',
        fontSize: 12,
        borderRadius: 5,
        padding: 8,
    },
    arrow: {
        color: theme.palette.green.dark,
    },
}))
