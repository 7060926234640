import React, { Fragment, Suspense } from 'react'
import { RouteItem } from 'utils/types'
import { Switch, Route } from 'react-router-dom'
import { PATH_NAME } from 'constants/index'
import { AuthGuard, GuestGuard } from 'guards'
import { useAppAbility } from 'casl/Can'
import { IAppAbility } from 'casl/ability'
import SettingAppBaseLayout from 'containers/Layouts/BaseLayout'
import AbilityRoute from './AbilityRoute'

const settingRoutes: RouteItem[] = [
    {
        path: PATH_NAME.LOGIN,
        exact: true,
        component: React.lazy(() => import('containers/Auth/Login')),
        guard: GuestGuard,
    },
    {
        path: PATH_NAME.LOGOUT,
        exact: true,
        component: React.lazy(() => import('containers/Auth/Logout')),
        guard: AuthGuard,
    },
    {
        path: PATH_NAME.NO_AUTHORIZATION,
        exact: true,
        component: React.lazy(() => import('containers/NoAuthorization')),
        guard: AuthGuard,
        hasAbility: (ability: IAppAbility) => ability.can('view', 'noAuthorization'),
    },
    {
        path: '/',
        guard: AuthGuard,
        layout: SettingAppBaseLayout,
        routes: [
            {
                path: PATH_NAME.HOME,
                exact: true,
                component: React.lazy(() => import('containers/Home')),
            },
            {
                path: PATH_NAME.PPA_OPERATOR.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/PPAOperator')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Vendor'),
            },
            {
                path: PATH_NAME.PPA_OPERATOR.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/PPAOperator/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Vendor'),
            },
            {
                path: PATH_NAME.PPA_OPERATOR.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/PPAOperator/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Vendor'),
            },
            {
                path: PATH_NAME.COMPANY.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/Company')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Company'),
            },
            {
                path: PATH_NAME.COMPANY.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/Company/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Company'),
            },
            {
                path: PATH_NAME.COMPANY.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/Company/CRUD')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Company'),
            },
            {
                path: PATH_NAME.SITE.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/SiteSetting')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Site'),
            },
            {
                path: PATH_NAME.SITE.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/SiteModify')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Site'),
            },
            {
                path: PATH_NAME.SITE.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/SiteModify')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Site'),
            },
            {
                path: PATH_NAME.SITE.EQUIPMENT_MODIFY,
                exact: true,
                component: React.lazy(() => import('containers/SiteModify')),
                hasAbility: (ability: IAppAbility) => ability.can('modify', 'Site'),
            },
            {
                path: PATH_NAME.USER.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/User')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Account'),
            },
            {
                path: PATH_NAME.USER.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/User/AddEdit')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'Account'),
            },
            {
                path: PATH_NAME.USER.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/User/AddEdit')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'Account'),
            },
            {
                path: PATH_NAME.MAINTENANCE_COMPANY.INDEX,
                exact: true,
                component: React.lazy(() => import('containers/MaintenanceCompany')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'MaintenanceCompany'),
            },
            {
                path: PATH_NAME.MAINTENANCE_COMPANY.CREATE,
                exact: true,
                component: React.lazy(() => import('containers/MaintenanceCompany/Create')),
                hasAbility: (ability: IAppAbility) => ability.can('create', 'MaintenanceCompany'),
            },
            {
                path: PATH_NAME.MAINTENANCE_COMPANY.DETAIL,
                exact: true,
                component: React.lazy(() => import('containers/MaintenanceCompany/Create')),
                hasAbility: (ability: IAppAbility) => ability.can('view', 'MaintenanceCompany'),
            },
        ],
    },
]

const renderRoutes = (routes: RouteItem[], ability: any) => {
    return routes ? (
        <Switch>
            {routes.map((route: RouteItem, idx: number) => {
                const Guard = route.guard || Fragment
                const Layout = route.layout || Fragment
                const Component = route.component
                const hasAbility = route.hasAbility

                return (
                    <Route
                        key={`routes-${idx}`}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any) => (
                            <Guard>
                                <Layout>
                                    <Suspense fallback={null}>
                                        {route.routes ? (
                                            renderRoutes(route.routes, ability)
                                        ) : (
                                            <AbilityRoute hasAbility={hasAbility}>
                                                <Component {...props} />
                                            </AbilityRoute>
                                        )}
                                    </Suspense>
                                </Layout>
                            </Guard>
                        )}
                    />
                )
            })}
        </Switch>
    ) : null
}

function Routes() {
    const abilitySetting = useAppAbility()
    return renderRoutes(settingRoutes, abilitySetting)
}

export default Routes
