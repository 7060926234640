export const BILLING_TYPE = {
    ALL: 'all', // 一括
    SINGLE: 'single', // 個別
}

export const FEE_TYPE = {
    OTHER: 1, // 先方
    SELF: 2, // 当方
}

export const FEE_TYPE_OPTIONS = [
    { value: 1, label: '先方' },
    { value: 2, label: '当方' },
]

export const ROUNDING = {
    ROUND_DOWN: 1,
    ROUND_UP: 2,
    ROUNDING_OFF: 3,
}

export const ROUNDING_OPTIONS = [
    { value: 1, label: '切り捨て' },
    { value: 2, label: '切り上げ' },
    { value: 3, label: '四捨五入' },
]

export const RP_CONTROL_TYPE = {
    NONE: 0,
    PCS_NUMBER_CONTROL: 1,
    REMOTE_MONITORING_SYSTEM: 2,
}

export const RP_CONTROL_OPTIONS = [
    { value: 0, label: 'なし' },
    { value: 1, label: 'PCS台数制御' },
    { value: 2, label: '遠隔監視システム' },
]

export const TAX_ROUNDING_OPTIONS = [
    { value: 1, label: '切り捨て' },
    { value: 2, label: '切り上げ' },
    { value: 3, label: '四捨五入' },
]

export const CHARGE_ROUNDING_OPTIONS = [
    { value: 1, label: '切り捨て' },
    { value: 2, label: '切り上げ' },
    { value: 3, label: '四捨五入' },
]

export const PROJECT_TYPE = {
    SELF_CONSUMPTION: 1,
    SURPLUS: 2,
    PoC: 99,
}

export const PROJECT_TYPE_OPTIONS = [
    { value: 1, label: '自家消費' },
    { value: 2, label: '余剰' },
    { value: 99, label: 'PoC' },
]

export const PAYMENT_TYPE = {
    BATCH: { value: 'all', label: '一括' },
    INDIVIDUAL: { value: 'single', label: '個別' },
}

export const IS_FIT_OPTIONS = [
    { value: 0, label: 'non-fit' },
    { value: 1, label: 'fit' },
]

export const EQUIPMENT_TYPE_OPTIONS = [
    { value: 'eMeters', label: '電力計' },
    { value: 'smartMeters', label: 'スマートメーター' },
    { value: 'sensors', label: 'センサー' },
    { value: 'battery', label: '蓄電池' },
    { value: 'v2h', label: 'V2H' },
    { value: 'pcs', label: 'PCS' },
    { value: 'coreDevice', label: 'コアデバイス' },
    { value: 'remoteIO', label: 'リモートI/O' },
    { value: 'airUnit', label: 'AiR用ユニット' },
    { value: 'storageBattery', label: '産業用蓄電池' },
]

export const AC_TYPE_OPTIONS = [
    { value: 'none', label: 'なし' },
    { value: 'single_phase', label: '単相' },
    { value: 'three_phase', label: '三相' },
]

export const EQUIPMENT_STATUS = [
    { value: '10', label: '正常' },
    { value: '20', label: '故障' },
    { value: '30', label: '撤去' },
    { value: '40', label: '停止' },
    { value: '50', label: '準備' },
    { value: '60', label: '劣化' },
]

export const REMOTE_CONTROLS = [
    { value: 'reboot', label: 'リブート' },
    { value: 'stop', label: '停止' },
    { value: 'start', label: '起動' },
]

export const EQUIPMENT_ACL = [
    { value: 'ppa', label: 'PPA' },
    { value: 'company', label: '利用企業' },
    { value: 'maintenanceCompany', label: 'メンテナンス事業者' },
]

// EV利用用途
export const TARGET_EV = [
    { value: 0, label: 'なし' },
    { value: 1, label: '一般車両用' },
    { value: 2, label: '配達車両用' },
]

export const USER_LEVEL_VALUES = [
    {
        name: 'admin',
        value: 99,
    },
    {
        name: 'maintenanceCompany',
        value: 4,
    },
    {
        name: 'vendor',
        value: 3,
    },
    {
        name: 'company',
        value: 2,
    },
    {
        name: 'site',
        value: 1,
    },
]

export const MQTTT_PUBLISHABLE = [
    { value: 0, label: '非対応' },
    { value: 1, label: '対応' },
]

export const MEASUREMENT_TARGET = [
    { value: 0, label: '対象外' },
    { value: 1, label: '対象' },
]

export const OPEN_24H = [
    { value: 0, label: '営業時間指定' },
    { value: 1, label: '24時間営業' },
]

export const SEND_METEO = [
    { value: 0, label: 'しない' },
    { value: 1, label: 'する' },
]

export const AREA_OPTIONS = [
    { value: 'hokkaido', label: '北海道' },
    { value: 'tohoku', label: '東北' },
    { value: 'tokyo', label: '東京' },
    { value: 'chubu', label: '中部' },
    { value: 'hokuriku', label: '北陸' },
    { value: 'kansai', label: '関西' },
    { value: 'chugoku', label: '中国' },
    { value: 'shikoku', label: '四国' },
    { value: 'kyushu', label: '九州' },
    { value: 'okinawa', label: '沖縄' },
]

export const PAGINATION_TYPE = {
    ACCOUNT: 'account',
    COMPANY: 'company',
    PPA: 'ppa',
    MAINTENANCE: 'maintenance',
}

export const LIST_DATA_TYPE = {
    LIST_EQUIPMENTS: 'listEquipments',
}

export const ACCOUNT_TYPE_OPTIONS = [
    { value: 1, label: '普通' },
    { value: 2, label: '当座' },
]

export const ACCOUNT_DATE_OF_ISSUE = [
    { value: 0, label: '発行日当日' },
    { value: 1, label: '検針対象月末日' },
]

export const ACCOUNT_PAYMENT_DEADLINE = [
    { value: 2, label: '翌月20日' },
    { value: 0, label: '翌月25日' },
    { value: 1, label: '翌月末日' },
]

export const OPTION_ALL = {
    label: 'すべて',
    value: '',
}

export const DEFAULT_DISPLAY_LABEL = '-'

export const DEFAULT_SEPARATOR = ','

export const EMPTY_OR_NULLISH_VALUES = [null, undefined, '']
