export const MaintenanceIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.32984 11.4763L13.3208 16.4673C13.3304 17.2065 13.6258 17.9138 14.1511 18.4407C14.6875 18.9772 15.4012 19.2725 16.1596 19.2725C16.918 19.2725 17.6317 18.9772 18.1682 18.4407C18.7046 17.9042 19 17.1905 19 16.4322C19 15.6738 18.7046 14.9601 18.1682 14.4236C17.5806 13.8361 16.7679 13.5407 15.9457 13.5998L11.076 8.7301C11.5183 7.27878 11.1319 5.69334 10.0462 4.60764C8.92058 3.48363 7.22498 3.10523 5.72736 3.64329C5.55971 3.70396 5.43518 3.84446 5.39686 4.01849C5.35854 4.19252 5.41123 4.37294 5.53577 4.49907L7.11641 6.07972L6.72365 7.1255L5.67786 7.51827L4.09722 5.93762C3.97109 5.81149 3.79067 5.7588 3.61664 5.79872C3.44261 5.83703 3.3021 5.96157 3.24143 6.12921C2.70337 7.62684 3.08177 9.32244 4.20579 10.4465C5.29148 11.5322 6.87692 11.9185 8.32824 11.4763H8.32984ZM4.04293 7.35062L5.17972 8.48741C5.32342 8.63111 5.53896 8.67741 5.72896 8.60556L7.30801 8.01322C7.44851 7.96053 7.55867 7.85036 7.61136 7.70986L8.20371 6.13081C8.27555 5.94081 8.22925 5.72527 8.08556 5.58158L6.94877 4.44479C7.81573 4.39849 8.68269 4.71462 9.31175 5.34368C10.1867 6.21862 10.4549 7.52625 9.9967 8.67581C9.92006 8.869 9.96477 9.08774 10.1117 9.23463L15.3917 14.5146C15.5114 14.6344 15.6806 14.6887 15.8483 14.6583C16.4279 14.5561 17.0218 14.7445 17.4353 15.1581C17.7754 15.4981 17.9638 15.9516 17.9638 16.4322C17.9638 16.9127 17.777 17.3662 17.4353 17.7063C17.0952 18.0463 16.6418 18.2347 16.1612 18.2347C15.6806 18.2347 15.2272 18.0479 14.8871 17.7063C14.5167 17.3358 14.3251 16.8217 14.365 16.2964C14.3762 16.1464 14.3219 15.9979 14.215 15.8909L8.83596 10.5119C8.73697 10.4129 8.60445 10.3602 8.46874 10.3602C8.40328 10.3602 8.33942 10.373 8.27715 10.397C7.12759 10.8568 5.81996 10.587 4.94502 9.71201C4.31595 9.08295 3.99823 8.21599 4.04613 7.34903L4.04293 7.35062Z"
                fill="currentColor"
            />
            <path
                d="M16.7092 16.9815C17.0125 16.6782 17.0125 16.1864 16.7092 15.8846C16.4058 15.5829 15.914 15.5813 15.6123 15.8846C15.3089 16.188 15.3089 16.6797 15.6123 16.9815C15.9156 17.2849 16.4074 17.2849 16.7092 16.9815Z"
                fill="currentColor"
            />
        </svg>
    )
}
