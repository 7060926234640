interface IProps {
    className?: string
}

export const MenuIcon = ({ ...rest }: IProps) => {
    return (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect width="18" height="1.5" fill="currentColor" />
            <rect y="5" width="18" height="1.5" fill="currentColor" />
            <rect y="10" width="18" height="1.5" fill="currentColor" />
        </svg>
    )
}
